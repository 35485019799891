import React, { useState } from 'react';

import { Button, Stack } from '@grafana/ui';

import { Collector, listCollectors, useQuery } from '@grafana-cloud/fleet-management-api';
import { columns } from 'feature/collector-list/components/CollectorListTable/TableColumns';
import { AttributesPanel } from 'feature/common/components/Attributes/AttributesPanel';
// import { useAppSelector } from 'feature/common/store/Store';
import { Attributes } from 'feature/common/types/Attributes';
import { attributesToMatchers } from 'feature/common/utils/attributes';

import { AttributeEditModal } from '../AttributeEditModal/AttributeEditModal';

import { EditButton } from './EditButton/EditButton';
import { SearchInput } from './SearchInput';

type Props = {
  setVisibleColumns: (shownColumns: typeof columns) => void;
  setFilter: (filter: string) => void;
  onMatchersChange: React.Dispatch<React.SetStateAction<string[]>>;
  allowSearch: boolean;
  allowSelect: boolean;
  allowFilter: boolean;
  filteredCollectors: Collector[];
};

export const CollectorListBar = ({
  setFilter,
  setVisibleColumns,
  allowSearch,
  allowSelect,
  onMatchersChange,
  allowFilter,
  filteredCollectors,
}: Props) => {
  const { data: collectors } = useQuery(listCollectors);

  const [attributes, setAttributes] = useState<Attributes>([]);
  const [showModal, setShowModal] = useState(false);

  const onAttributesChange = (attributes: Attributes) => {
    setAttributes(attributes);
    onMatchersChange(attributesToMatchers(attributes));
  };

  // const selectedCollectors = useAppSelector((state) => state.collectorList.selectedCollectorIds);
  // const showBulkEditButton = selectedCollectors.length > 0;
  // disable for now as the feature is not implemented
  const showBulkEditButton = false;
  const collectorsLength = collectors?.collectors.length ?? 0;
  const filteredCollectorsLength = filteredCollectors.length;

  return (
    <>
      <Stack direction={'column'}>
        <Stack alignItems={'center'} justifyContent={'space-between'}>
          <span>
            {filteredCollectorsLength === collectorsLength ? '' : `${filteredCollectorsLength}/`}
            {collectorsLength} collector{collectorsLength === 1 ? '' : 's'}
          </span>
          {allowSearch && <SearchInput onFilterChange={setFilter} />}
          <EditButton setVisibleColumns={setVisibleColumns} allowSelect={allowSelect} />
        </Stack>
        <Stack>
          {allowFilter && <AttributesPanel attributes={attributes} onAttributesChange={onAttributesChange} />}
          {showBulkEditButton && (
            <Button variant="secondary" onClick={() => setShowModal(true)}>
              Edit custom attributes
            </Button>
          )}
        </Stack>
      </Stack>
      {showModal && <AttributeEditModal setShowModal={setShowModal} />}
    </>
  );
};
